<script>
// import utils from '@/utils.js'
import PrettyDate from '@/components/PrettyDate.vue'

let modelsByName = {
  kid_tag: 'Kid Tag',
  bag_tag: 'Bag Tag',
  sticker: 'Sticker',
  key_tag: 'Key Tag',
  wallpaper: 'Wallpaper',
}

export default {
  name: 'ProtectSummary',

  components: { PrettyDate },

  props: {
    report: {
      type: Object,
      required: true,
    },
  },

  computed: {
    isDogTag() {
      return this?.report?.model == 'dog_tag'
    },

    isTagAKid() {
      return this?.report?.model == 'kid_tag'
    },

    isWallpaper() {
      return this?.report?.model == 'wallpaper'
    },

    isPreregistration() {
      return this?.report?.isPreregistration
    },

    isTag() {
      return ['bag_tag', 'key_tag'].includes(this?.report?.model)
    },

    hasShowChildInfo() {
      return this.report?.preferences?.show_child_details
    },

    hasShowContactInfo() {
      return this.report?.preferences?.show_contact_info
    },
  },

  methods: {
    tagName(tag) {
      return tag?.details?.name ?? ''
    },

    tagPrettyName(model) {
      return modelsByName[model] || 'Tag'
    },

    artUrl(report) {
      return (
        report?.artUrl ||
        report?.details?.image_url ||
        'https://bulma.io/images/placeholders/128x128.png'
      )
    },
  },
}
</script>

<template v-if="report">
  <div class="is-flex protect-summary" style="justify-content: space-between">
    <template v-if="isWallpaper">
      <o-icon icon="cellphone" size="large" />
    </template>

    <span v-else class="has-text-centered" style="min-width: 4rem">
      <figure v-if="isTagAKid" class="image is-64x64 m-0">
        <img
          class="is-rounded"
          :src="artUrl(report)"
          style="max-height: 4rem" />
      </figure>

      <figure v-else class="m-0">
        <img :src="artUrl(report)" style="max-height: 4rem" />
      </figure>
    </span>

    <template v-if="!isTagAKid">
      <span style="flex-grow: 2">
        <p class="mb-0">{{ report.tagName }}</p>
      </span>

      <span style="min-width: 4rem">
        <p class="mb-0">{{ report.organization }}</p>
        <PrettyDate :date="report.dateCreated" class="mb-0 is-size-7" />
        <p class="mb-0">{{ report.model }}</p>
      </span>
    </template>

    <template v-else-if="isPreregistration">
      <div>
        <p class="mb-0">
          {{ tagName(report) }} - {{ tagPrettyName(report.model) }}
        </p>
      </div>

      <div class="has-text-right prefs-col">
        <p class="has-text-danger">Preregistration</p>
      </div>
    </template>

    <template v-else>
      <div>
        <p class="mb-0">
          {{ tagName(report) }} - {{ tagPrettyName(report.model) }}
        </p>
      </div>
      <div class="has-text-right prefs-col">
        <span style="">
          <span class="mr-1 is-size-7">Child</span>
          <o-icon
            v-if="hasShowChildInfo"
            class="offset-icon"
            icon="eye"
            size="small" />
          <o-icon v-else class="offset-icon" icon="eye-off" size="small" />
        </span>
        <span style="">
          <span class="mr-1 is-size-7">Contact</span>
          <o-icon
            v-if="hasShowContactInfo"
            class="offset-icon"
            icon="eye"
            size="small" />
          <o-icon v-else class="offset-icon" icon="eye-off" size="small" />
        </span>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss">
.protect-summary {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  min-height: 6rem;
}

.offset-icon {
  vertical-align: middle;
}

.prefs-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
