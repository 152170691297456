import VueGtag from 'vue-gtag'

export default {
  setup(app, router, prefix, config) {
    // config: provide the whole config or nothing

    console.log('configuring google analytics')
    prefix = prefix || 'default'
    if (config === undefined) {
      const appConfig = JSON.parse(import.meta.env.VITE_APP_FIREBASE)
      config = config || { config: { id: appConfig.measurementId } }
      config.pageTrackerTemplate = (to) => {
        return {
          page_title: `${prefix}: ${to.name}`,
          page_path: to.path,
        }
      }
    }

    app.use(VueGtag, config, router)
  },
}
