import AccountProtect from '@/accounts/views/account/AccountProtect.vue'
import AccountProfile from '@/accounts/views/account/AccountProfile.vue'
import ProtectDetails from '@/accounts/views/ProtectDetails.vue'
import ProtectRegister from '@/accounts/views/ProtectRegister.vue'
import FreqAskedQuestions from '@/accounts/views/FreqAskedQuestions.vue'

import AuthView from '@/accounts/views/AuthView.vue'
import LoginView from '@/accounts/views/LoginView.vue'
import LogoutView from '@/accounts/views/LogoutView.vue'

export default [
  {
    path: '/protect',
    redirect: '/',
  },
  {
    path: '/',
    name: 'account-protect',
    component: AccountProtect,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/protect/:key/register',
    name: 'account-protect-register',
    component: ProtectRegister,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/protect/:key',
    name: 'account-protect-details',
    component: ProtectDetails,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/profile',
    name: 'account-profile',
    component: AccountProfile,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/auth/:token',
    name: 'login-token',
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/auth/:token/protect/:protectKey',
    name: 'login-token-protect',
    component: AuthView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/logout',
    name: 'logout',
    component: LogoutView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/faq',
    name: 'account-faq',
    component: FreqAskedQuestions,
    meta: {
      requiresAuth: true,
    },
  },
]
