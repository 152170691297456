import Rollbar from 'rollbar'

let autoInstrument = import.meta.env.VITE_DEBUG == 'true' && {
  network: true,
  log: false,
  dom: false,
  navigation: false,
  connectivity: true,
  contentSecurityPolicy: true,
  errorOnContentSecurityPolicy: false,
}

const rollbar = new Rollbar({
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  autoInstrument: autoInstrument,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: __COMMIT_HASH__,
    environment: import.meta.env.VITE_ROLLBAR_ENV,
    // Add custom data to your events by adding custom key/value pairs like the one below
  },
})

export default rollbar
