<script>
import { nextTick } from 'vue'

export default {
  name: 'LostModeWidget',

  components: {},

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    message: {
      type: String,
      default: null,
    },
  },

  emits: ['update:modelValue', 'blur', 'update:message'],

  data: () => ({}),

  computed: {},

  mounted() {},

  methods: {
    async markLost(mode) {
      this.$emit('update:modelValue', mode)

      await nextTick()
      if (mode) {
        this.$refs.lostMessage.focus()
      }
      // TODO: delay with debounce
      this.$emit('blur')
    },
  },
}
</script>

<template>
  <div class="mb-4">
    <template v-if="modelValue">
      <o-field label="Lost Message">
        <o-input
          ref="lostMessage"
          :model-value="message"
          style="min-height: 5rem"
          type="textarea"
          expanded
          @update:model-value="$emit('update:message', $event)"
          @blur="$emit('blur')" />
      </o-field>

      <div class="has-text-centered">
        <o-button variant="text" @click="markLost(false)">
          Mark as Found
        </o-button>
      </div>
    </template>

    <template v-else>
      <div class="has-text-centered">
        <o-button
          variant="danger"
          style="min-width: 20rem"
          rounded
          @click="markLost(true)">
          Mark as Lost
        </o-button>
      </div>
    </template>
  </div>
</template>

<style scoped lang="scss"></style>
