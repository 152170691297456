import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'
import { useAppStore } from '@/stores'

import liffapi from '@/liffapi.js'

export const useAccountStore = defineStore({
  id: 'account',

  state: () => ({
    auth: useLocalStorage('auth', {
      email: null,
      token: null,
      refreshToken: null,
    }),

    user: useLocalStorage('user', {
      name: null,
      email: null,
      phoneNumber: null,
    }),

    protectDetails: {
      friends: [],
      registrations: [],
    },
  }),

  getters: {
    hasFriends(state) {
      return state.protectDetails?.friends?.length > 0
    },

    friendCount(state) {
      return state.protectDetails?.friends?.length || 0
    },

    hasReports(state) {
      return state.protectDetails?.registrations?.length > 0
    },

    claims(state) {
      return state.user?.claims ?? []
    },

    alerts(state) {
      return state.user?.alerts ?? []
    },

    isAuthed(state) {
      return Boolean(state.auth?.token) && Boolean(state.auth?.email)
    },
  },

  actions: {
    updateUser(user) {
      this.user = Object.assign({}, user)
    },

    setAuth(email, token, refreshToken) {
      this.auth.email = email
      this.auth.token = token
      if (refreshToken) this.auth.refreshToken = refreshToken
    },

    clearAuth() {
      this.auth = Object.assign(this.auth, { token: null })
    },

    clearUser() {
      this.updateUser({ email: null, phoneNumber: null, name: null })
    },

    getProtectDetails() {
      return liffapi.account
        .getProtectDetails(this.auth.token)
        .then((response) => {
          this.protectDetails = Object.assign(
            this.protectDetails,
            response.entity
          )
          return response
        })
        .catch(this.errorHandler)
    },

    registerProtect(url) {
      return liffapi.account
        .registerProtect(this.auth.token, url)
        .then((response) => {
          this.protectDetails = Object.assign(
            this.protectDetails,
            response.account
          )
          return response
        })
        .catch(this.errorHandler)
    },

    updateProtect(form) {
      return liffapi.account
        .updateProtect(this.auth.token, form)
        .then((response) => {
          this.protectDetails = Object.assign(
            this.protectDetails,
            response.entity
          )
          return response
        })
        .catch(this.errorHandler)
    },

    removeFriend(n) {
      this.protectDetails.friends.splice(n, 1)
      return liffapi.account
        .updateFriends(this.auth.token, {
          friends: this.protectDetails.friends,
        })
        .catch(this.errorHandler)
    },

    addFriend(friend) {
      this.protectDetails.friends.push(friend)
      return liffapi.account
        .updateFriends(this.auth.token, {
          friends: this.protectDetails.friends,
        })
        .catch(this.errorHandler)
    },

    updateFriend(n, friend) {
      console.debug(n, friend)
      this.protectDetails.friends.splice(n, 1, friend)
      return liffapi.account
        .updateFriends(this.auth.token, {
          friends: this.protectDetails.friends,
        })
        .catch(this.errorHandler)
    },

    /*
     *  email: str
     *  redirect: str, redirect token [protect]
     *  reference: UrlsafeKey, a thing to remember when authing
     */
    async login(email, redirect, reference) {
      let app = useAppStore()
      let form = {
        email: email,
        appcheck: await app.token,
      }
      if (reference) form.reference = reference

      return liffapi.account.login(form, redirect)
    },

    updateProfile(form) {
      return liffapi.account
        .updateProfile(this.auth.token, form)
        .then((response) => {
          this.updateUser(response.entity)
          return response
        })
        .catch(this.errorHandler)
    },

    errorHandler(err) {
      console.error('err ', err.status)
      if (err.status == 401) {
        this.clearUser()
        this.$router.push('/login')
      }
      throw err
    },
  },
})
