import { createRouter, createWebHashHistory } from 'vue-router'
import accountRoutes from './account.js'
import { useAccountStore } from '@/stores'

let routes = []

routes = routes.concat(accountRoutes)

const router = createRouter({
  history: createWebHashHistory(),

  routes: routes,

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach((to) => {
  const store = useAccountStore()

  if (to.meta.requiresAuth && !store.isAuthed) {
    store.clearUser()
    return '/login'
  }
})
export default router
