<script>
// import utils from '@/utils.js'

export default {
  name: 'FriendSummary',

  components: {},

  props: {
    friend: {
      type: Object,
      required: true,
    },
  },

  emits: ['edit', 'delete'],

  computed: {},

  methods: {},
}
</script>

<template>
  <div v-if="friend" class="friend-summary box">
    <div>
      <o-icon size="large" icon="account-tie"></o-icon>
    </div>
    <div>
      <p>{{ friend.name }}</p>
      <p>{{ friend.email }}</p>
    </div>
    <div>
      <o-icon
        v-if="false"
        :class="isVerified(friend) ? 'has-text-success' : 'has-text-light'"
        icon="check-circle-outline"></o-icon>
      <a class="" @click="$emit('edit', friend, n)">
        <o-icon icon="pencil-outline"></o-icon>
      </a>
      <a @click="$emit('delete', n)">
        <o-icon icon="delete-outline"></o-icon>
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.friend-summary {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
}
</style>
