<script>
import { mapStores } from 'pinia'

import { useAccountStore } from '@/stores'
// import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

function asForm(user) {
  return (({ name, phoneNumber }) => ({ name, phoneNumber }))(user)
}

export default {
  name: 'AccountProfile',

  components: {},

  props: {},

  data: () => ({
    isDebug: import.meta.env.VITE_DEBUG == 'true',
    isLoading: false,
    form: {
      name: null,
      phoneNumber: null,
    },
    errors: {
      name: null,
      phoneNumber: null,
    },
    filter: null,
    isEditing: false,
  }),

  computed: {
    ...mapStores(useAccountStore),

    ok() {
      let valid = utils.isValidName(this.form.name)
      valid &= utils.isValidPhoneNumber(this.form.phoneNumber)
      return valid
    },
  },

  mounted() {
    this.form = asForm(this.account.user)
  },

  methods: {
    filterClass(btn) {
      return this.filter == btn ? 'is-primary is-light' : 'is-grey-lightest'
    },

    submit(form) {
      console.log(form)
      this.isLoading = true
      this.account
        .updateProfile(form)
        .then((response) => {
          utils.toast(response.message, 'success')
        })
        .finally(() => (this.isLoading = false))
    },

    validateName(val) {
      let err = null
      if (val === null || val === '') {
        err = 'Please provide your name.'
      } else if (val?.length < 4) {
        err = 'Please provide your full name'
      }
      this.errors = Object.assign(this.errors, { name: err })
    },

    validatePhone(val) {
      // UK: +441132201916
      let err = null
      if (utils.isValidPhoneNumber(val)) {
        this.errors = Object.assign(this.errors, { phoneNumber: err })
        return
      }
      if (val === null || val === '') {
        err = 'Please enter a value.'
      } else {
        err = 'Try adding a country code (e.g. +1, +44)'
      }
      this.errors = Object.assign(this.errors, { phoneNumber: err })
    },
  },
}
</script>

<template>
  <div class="content account-view">
    <nav class="breadcrumb has-dot-separator" aria-label="breadcrumbs">
      <ul>
        <li class="is-active">
          <router-link to="/profile" class="" aria-current="page">
            Profile
          </router-link>
        </li>
      </ul>
    </nav>

    <section>
      <o-field label="Email">
        <p>{{ account.user.email }}</p>
      </o-field>

      <o-field
        label="Full Name"
        :message="errors.name"
        :variant="errors.name ? 'danger' : ''">
        <o-input
          v-model="form.name"
          :disabled="!isEditing"
          validation-message="Full name; We match this to found IDs."
          expanded
          @blur="validateName(form.name)" />
      </o-field>

      <o-field
        label="Phone Number"
        :message="errors.phoneNumber"
        :variant="errors.phoneNumber ? 'danger' : ''">
        <o-input
          v-model="form.phoneNumber"
          :disabled="!isEditing"
          type="tel"
          required
          expanded
          @blur="validatePhone(form.phoneNumber)" />
      </o-field>

      <div class="is-flex" style="justify-content: flex-end">
        <o-button v-if="!isEditing" variant="primary" @click="isEditing = true">
          Update
        </o-button>

        <o-button
          v-else
          :class="{ 'is-loading': isLoading }"
          variant="primary"
          @click="submit(form)">
          Save
        </o-button>
      </div>
    </section>

    <section v-if="false">
      <h3>Email</h3>
      <p>You are logged in as: {{ account.user.email }}</p>
      <div class="is-flex is-justify-content-center">
        <o-button> Change my email </o-button>
      </div>
    </section>

    <section v-if="false">
      <h3>Password</h3>
      <p>
        You can set a permanent password if you don't want to use temporary
        login links.
      </p>
      <div class="is-flex is-justify-content-center">
        <o-button>Set a password</o-button>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped></style>
