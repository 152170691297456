<script>
import { mapStores, mapWritableState } from 'pinia'

import { useAccountStore, useOptionsStore, useAppStore } from '@/stores'
import liffapi from '@/liffapi.js'
import utils from '@/utils.js'

export default {
  name: 'AuthView',

  components: {},

  props: {},

  data: () => ({
    isLoading: false,
    requireConfirmEmail: false,
    form: {
      email: null,
      token: null,
      appcheck: null,
    },
  }),

  computed: {
    ...mapWritableState(useOptionsStore, ['opts']),
    ...mapStores(useAppStore, useAccountStore),

    ok() {
      return utils.isValidEmail(this.form.email)
    },
  },

  mounted() {
    if (!this.$route.params.token) {
      this.$router.replace({ name: 'login' })
    }

    if (utils.isValidEmail(this.account?.auth?.email)) {
      let form = {
        email: this.account.auth.email,
        token: this.$route.params.token,
      }
      this.submit(form)
    } else {
      this.requireConfirmEmail = true
      this.form.token = this.$route.params.token
    }
  },

  methods: {
    async submit(form) {
      this.isLoading = true
      let appcheck = await this.app.token
      form.appcheck = appcheck
      let protectKey = this.$route.params?.protectKey
      liffapi.account
        .auth(form)
        .then((response) => {
          utils.toast(response.message, 'success')
          this.account.setAuth(response.entity.email, form.token)
          this.account.updateUser(response.entity)
          let slug = location.host.split('.')[0]
          liffapi.event('consumer_login', slug, response?.entity?.key)
          if (protectKey) {
            return this.$router.push(`/protect/${protectKey}/register`)
          }
          this.$router.push('/')
        })
        .catch((err) => {
          console.error(err)
          let msg =
            err?.data?.message ||
            err.toString() ||
            'There was an error. Please try again later.'
          if (err.status == 409) {
            this.account.auth.email = null
            this.requireConfirmEmail = true
          }
          utils.toast(msg, 'danger')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<template>
  <div v-if="requireConfirmEmail" class="account-view">
    <section class="content">
      <h1 class="mb-6 has-text-centered">Login</h1>

      <p class="mb-6">
        Looks like you’re signing in on a different device. Please confirm the
        email address on your account.
      </p>
    </section>
    <section class="has-text-centered">
      <o-field class="" style="" label="">
        <o-input
          v-model="form.email"
          type="email"
          list="defaultEmails"
          required
          expanded
          placeholder="Enter your email"></o-input>
      </o-field>

      <o-field class="">
        <o-button
          :disabled="!ok"
          class="is-fat-btn is-liff"
          :class="{ 'is-loading': isLoading }"
          variant="primary"
          size="medium"
          @click="submit(form)">
          Continue
        </o-button>
      </o-field>
    </section>
  </div>
  <div v-else class="account-view has-text-centered">
    <o-loading :full-page="false" :active="isLoading"> </o-loading>
  </div>
</template>

<style lang="scss">
section {
  margin-bottom: 2rem;
}
</style>

<style lang="scss" scoped></style>
