<script setup>
import { RouterView } from 'vue-router'
import { mapStores } from 'pinia'

import { useAccountStore, useAppStore } from '@/stores'
import LiffFooter from '@/components/LiffFooter.vue'
import AccountNav from './components/AccountNav.vue'
</script>

<script>
export default {
  name: 'LiffAccounts',

  data: () => ({
    notifications: [],
    version: __COMMIT_HASH__,
  }),

  computed: {
    ...mapStores(useAccountStore, useAppStore),
  },

  created() {
    this.app.initialize()
  },

  mounted() {},

  methods: {
    info(msg) {
      this.$rollbar.info(msg)
    },
  },
}
</script>

<template>
  <div>
    <AccountNav> </AccountNav>

    <RouterView style="" />

    <LiffFooter class="mt-6"></LiffFooter>
  </div>
</template>

<style>
@import '@mdi/font/css/materialdesignicons.css';
</style>
