import { defineStore } from 'pinia'
import { useLocalStorage } from '@vueuse/core'

export const useOptionsStore = defineStore({
  id: 'options',

  state: () => ({
    opts: useLocalStorage('opts', {}),

    deviceOptions: useLocalStorage('deviceOptions', {}),
  }),

  getters: {},

  actions: {},
})
