<script>
import { mapStores } from 'pinia'
import { useAccountStore } from '@/stores'
// import liffapi from '@/liffapi.js'
// import utils from '@/utils.js'

export default {
  name: 'AccountNav',

  components: {},

  props: {},

  data: () => ({
    isOpen: false,
  }),

  computed: {
    ...mapStores(useAccountStore),

    menuActive() {
      return this.isOpen ? 'is-active' : ''
    },
  },

  mounted() {},

  methods: {},
}
</script>

<template>
  <header>
    <nav class="navbar is-fixed-top" role="navigation">
      <div class="navbar-brand">
        <img
          src="https://storage.googleapis.com/liff-concept-183718.appspot.com/assets/liffapp-logo.png"
          style="max-height: 3rem; padding: 0.25rem"
          alt="Returning the world's lost property." />

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="accountNav"
          @click="isOpen = !isOpen">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="accountNav" class="navbar-menu" :class="menuActive">
        <div v-if="account.isAuthed" class="navbar-start">
          <router-link
            to="/"
            class="navbar-item is-expanded is-flex"
            @click="isOpen = false">
            <o-icon icon="shield-check-outline"></o-icon>
            <span class="is-size-5">Protect</span>
          </router-link>

          <router-link
            to="/profile"
            class="navbar-item is-expanded is-flex"
            @click="isOpen = false">
            <o-icon icon="account-outline"></o-icon>
            <span class="is-size-5">Profile</span>
          </router-link>

          <router-link
            to="/faq"
            class="navbar-item is-expanded is-flex"
            @click="isOpen = false">
            <o-icon icon="help-circle-outline"></o-icon>
            <span class="is-size-5">FAQ</span>
          </router-link>
        </div>
        <div class="navbar-end">
          <template v-if="account.isAuthed">
            <div class="navbar-item is-expanded">
              <router-link
                to="/logout"
                class="is-light button"
                @click="isOpen = false">
                Logout
              </router-link>
            </div>
          </template>

          <template v-else>
            <div class="navbar-item is-expanded">
              <router-link
                to="/login"
                class="is-light button"
                @click="isOpen = false">
                Login
              </router-link>
            </div>
          </template>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped lang="scss">
nav.account {
  gap: 1rem;
  justify-content: center;
}

#accountNav {
  height: auto;
  @media (max-width: 768px) {
    height: 100vh;
  }
}
</style>
<style lang="scss">
.navbar-item.router-link-active {
  background-color: #00d1b2 !important;
}
</style>
